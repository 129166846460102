import React from "react"
import useTranslations from "../../../utils/useTranslations"
import FeaturesList from "../../../components/featuresList"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/homepage/screen-android-mobile.webp"
import heroImg from "../../../images/homepage/screen-android-mobile.png"
import heroImgWebp2x from "../../../images/homepage/screen-android-mobile@2x.webp"
import heroImg2x from "../../../images/homepage/screen-android-mobile@2x.png"

import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"

import { ANDROID_LINK_PRO, getAndroidLink } from "../../../utils/getAndroidLink";

const heroImgAlt = "Buy and download BitTorrent Pro for Android devices."

class BittorrentAndroidPro extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      androidLinkPro: ANDROID_LINK_PRO,
    };
  }

  componentDidMount(){
    this.setState({
      androidLinkPro: getAndroidLink('pro'),
    })
  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)

    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

    return (
      <>

        <div className="top-section-container p-0 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-dark py-2">

            <div className="row text-center mt-4 mt-sm-0">
              <div className="col-md-6 order-md-1 px-md-0 d-flex flex-column justify-content-center align-items-center">
                <Logo color="black" tag="h1" productName="Android Pro" className="mb-4"/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4 android-hero"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4 android-hero"></img>
                </picture>

                
                <p className="text-size-24 text-spacing-1 font-weight-normal">{t(`Get more out of the #1 Android torrent client.`)}</p>
                <ul className="product-hero-list mt-2">
                	<li>
                		{t(`No ads.`)}
                	</li>
                	<li>
                		{t(`Battery saving features.`)}
                	</li>
                	<li>
                		{t(`Save data with automatic shutdown.`)}
                	</li>
                </ul>
				<a href={this.state.androidLinkPro} id="product-android-pro" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
					<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
				</a>
              </div>
              <div className="col-md-6 mt-5 order-md-2 d-flex justify-content-center align-items-center">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img android-hero"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img android-hero"></img>
                </picture>

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`FeaturesHeader`)}
          </div>
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>
            <p className="text-center mb-5 text-size-24" >
              {t(`BitTorrent Pro Torrent Downloader for Android Features`)}
            </p>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>


        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`Compare`)}
          </div>
          <div className="container mb-5 text-center">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare Pro with BitTorrent for Android Free`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true}} />
        </div>


        <div className="container mb-5">
          <div className="word-cloud font-weight-bold">
          	BitTorrent <span className="font-weight-normal">
              Android Pro
            </span><span className="font-weight-bold">
              / 
            </span> <br/> <span className="text-size-32">{t(`Product Tip`)}</span>

          </div>
        </div>

        <div className="container my-md-5 py-md-5">
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="col-md-4 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`Why Choose the Pro<br>Version of BitTorrent<br><b>on Mobile?</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-7 product-faq-answer">
        			{t(`<b>Pro takes the most trusted Android torrent client</b> on the Google Play Store and adds several features to create a better BitTorrent mobile experience. In addition to being ad-free, Pro allows you to stop torrenting when your battery hits a predefined level. You can also stop torrenting when your downloads have finished to save on your mobile data plan. If you’re a Pro user, you can contact customer support if you have questions about your Android torrent client.`)} 

        		</div>
        	</div>

        </div>


      </>
    )

  }
}

export default BittorrentAndroidPro
